import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import ClientsModule from "../components/ClientsModule/ClientsModule"
import Client from "../components/ClientsModule/Client"
// import Features from "../components/Features/Features"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Thinking Cap"
          subTitle="Web engineering services and solutions"
        ></BannerModule>
        <BasicTextModule />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        {/* <Features /> */}
        <ClientsModule>
          <Client title="The Title" content="The content" />
        </ClientsModule>
      </Layout>
    </>
  )
}

export default Index
