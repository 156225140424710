import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
// import drupalLogoSvg from '../../images/logos/Drupal_Wordmark2_white_RGB.svg'
// import salesforceLogoSvg from '../../images/logos/Salesforce.com_logo-white.svg'




import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles>
      {/* <StaticImage
        className="perks__image--bg"
        src="../../images/perks-bg.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div> */}
      <div className="container">
        <Perk
          title="Bespoke online stores and APIs with Drupal Commerce"
          content="Customised online web shops based on Drupal Commerce with a bespoke licence server and API for a React front-end"
        >
          <img className="lazyload" alt="Drupal logo" data-src="/Drupal_Wordmark2_white_RGB.svg" style={{ width: "auto", height: "45px" }}/>
        </Perk>
        <Perk
          title="Salesforce and Drupal integration"
          content="Combine the best CRM with the best free enterprise-grade content management system."
        >
          <img className="lazyload" alt="Drupal logo" data-src="/Salesforce.com_logo-white.svg" style={{ width: "auto", height: "45px" }}/>
        </Perk>
        <Perk
          title="Community building using Open Social"
          content="Save time and kick start a new community using this highly regarded free Drupal distribution."
        >
          <StaticImage
            src="../../images/logos/opensocial_Logo-Blue-White-Text.png"
            alt="Open Social logo"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Perk>
        {/* <Perk
          title="Security and GDPR compliance"
          content="A static site reduces your chance of a security exposure through injection"
        >
          <img src={vrtLogoSvg} style={{ width: "auto", height: "45px" }}/>
        </Perk> */}
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
