import * as React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { ClientsModuleStyles } from "./ClientsModuleStyles"
// import homeinsteadLogoSvg from '../../images/logos/HI_Logo_Vertical_woTagline_RGB_white.svg'
// import autismcrcLogoSvg from '../../images/logos/autismcrc_logo_white.svg'
// import uqLogoSvg from '../../images/logos/university-of-queensland_white.svg'
// import candkLogoSvg from '../../images/logos/candk_logo_white.svg'
// import sasLogoSvg from '../../images/logos/sas_logo_white.svg'
// import vrtLogoSvg from '../../images/logos/vrt_logo_white.svg'



import Client from "./Client"

const ClientsModule = () => {
  return (
    <ClientsModuleStyles>
      {/* <StaticImage
        className="perks__image--bg"
        data-src="../../images/perks-bg.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div> */}
      {/* <h2>Recent clients</h2> */}
      <div className="gatsbyjsSlider">
        <div className="marquee">
          <div className="marquee_inner">
          <Client
            title="Autism CRC"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="Autism CRC logo" data-src="/autismcrc_logo_white.svg" /></span>
          </Client>
          <Client
            title="The University of Queensland"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="The University of Queensland logo" data-src="/university-of-queensland_white.svg" /></span>
          </Client>
          <Client
            title="C&K Childcare and Kindergarten"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="C&K logo" data-src="/candk_logo_white.svg" /></span>
          </Client>
          <Client
            title="Secret Agent Society"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="SAS Game logo" data-src="/sas_logo_white.svg" /></span>
          </Client>
          <Client
            title="VRT"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="VRT logo" data-src="/vrt_logo_white.svg" /></span>
          </Client>
          <Client
            title="Home Instead Senior Care"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="Home Instead Senior Care logo" data-src="/HI_Logo_Vertical_woTagline_RGB_white.svg" /></span>
          </Client>
          </div>

          <div className="marquee_inner">
          <Client
            title="Autism CRC"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="Autism CRC logo" data-src="/autismcrc_logo_white.svg" /></span>
          </Client>
          <Client
            title="The University of Queensland"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="The University of Queensland logo"  data-src="/university-of-queensland_white.svg" /></span>
          </Client>
          <Client
            title="C&K Childcare and Kindergarten"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="C&K logo" data-src="/candk_logo_white.svg" /></span>
          </Client>
          <Client
            title="Secret Agent Society"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="SAS Game logo" data-src="/sas_logo_white.svg" /></span>
          </Client>
          <Client
            title="VRT"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="VRT logo" data-src="/vrt_logo_white.svg" /></span>
          </Client>
          <Client
            title="Home Instead Senior Care"
            content=""
          >
            <span className="gatsby-image-wrapper"><img className="lazyload" alt="Home Instead Senior Care logo" data-src="/HI_Logo_Vertical_woTagline_RGB_white.svg" /></span>
          </Client>
          </div>

        </div>
      </div>
    </ClientsModuleStyles>
  )
}

export default ClientsModule
