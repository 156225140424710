import * as React from "react"
import { ClientStyles } from "./ClientsModuleStyles"

const Client = ({ children, title, content }) => {
  return (
    <ClientStyles>
      {children}
      {/* {title && <h2>{title}</h2>}
      {content && <p>{content}</p>} */}
    </ClientStyles>
  )
}

export default Client
