import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"

const BasicTextModule = () => {
  return (
    <BasicTextModuleStyles>
      <div className="container">
        <h2>
        We are Australian-based web engineers with broad and deep experience in web development and development operations. 
        </h2>
        <p>
        We work at all levels in the development stack – from early exploration of ideas, developing APIs, web development, architecting cloud infrastrucutre and creating automations to deploy your web app. 
        </p>
        <p style={{ marginBottom: "60px" }}>
        We use use Open Source software like Drupal, Gatsby and React to deliver beautiful web solutions for our clients.
        </p>
        <Button text="Read more about us" as={Link} to="/about" />
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
